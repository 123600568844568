import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../components/footer";
import Header from "../components/header";
import SinglePageHeader from '../components/singlepageheader';
import { SEO } from "../components/seo";


const validEmail = (text) => {
	var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
	console.log('validemila', text, pattern.test(text))
	return pattern.test(text);
}

const validContact = (text) => {
	var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

	return pattern.test(text);
}

const ContactUs = () => {

	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		contact: '',
		message: ''
	});
	const [error, setError] = useState({});
	const [loading, setLoding] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		
		let emptyState = Object.keys(formData).filter(fi => (formData[fi] == '' && fi !== 'message'))
		let error = {}
		if(emptyState.length) {
			
			emptyState.map(ma => {
				error[ma] = `Please enter a valid data`
			})
			
		} else if(!validEmail(formData.email)) {
			error['email'] = `EmailId is not valid`
			
		} else if(!validContact(formData.contact)) {
			error['contact'] = `Contact number is not valid`
			
		} else {
			setLoding(true);
			let today = new Date();
			var data = new FormData();
			data.append( "first_name", formData.first_name );
			data.append( "last_name", formData.last_name );
			data.append("email", formData.email);
			data.append("contact", formData.contact);
			data.append("message", formData.message);
			data.append("submitted_on", today);
			data.append("type", "Contact Form");

			axios.post('https://script.google.com/macros/s/AKfycbw4vw3liD1nOvVF3GHp5XndUpvqDFdoPN6Q7q4sWUd5gmKjKpy6j8VpcNpVpP5to1Ql/exec', data)
			.then(function (response) {
				if(response) {
					window.location = '/thank-you'
				}
			})
			.catch(function (error) {
				setLoding(false)
			});

		}
		setError(error);
		return error;
	}

	const handleChange = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		console.log('set frm data', name, value)
		setFormData({
			...formData,
			[name]: value
		});
		setError({
			...error,
			[name]: ''
		})
	}
   
    return(
        <div className="container">
			<SEO title="Contact Us" />
            <Header />
            <SinglePageHeader page="Drop Us a Mail" />
		
			<section class="section2 clearfix">
				<div class="col2 column1 first">
				<iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=736&amp;height=773&amp;hl=en&amp;q=Bussa%20Industrial%20Estate,%20lower%20parel%20Mumbai+(Pooja%20Services)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

				</div>
				<div class="col2 column2 last">
					<div class="sec2innercont">
						<div class="sec2addr">
							<p> Bussa Industries 3rd floor Hanuman Gully, Gala No. 306 Ganpatrao Kadam Marg Mumbai - 13 </p>
							<p><span class="collig">Phone :</span> +91 8356015503 / 9321759213</p>
							<p><span class="collig">Email :</span> poojaservices92@gmail.com </p>
						</div>
					</div>
					<div class="sec2contactform">
						<h3 class="sec2frmtitle">Want to Know More? Drop Us a Mail</h3>
						<form id="contact_form" onSubmit={handleSubmit}>
							<div class="clearfix">
								<label className="col2 first">
									<input type="text" placeholder="First Name" name="first_name" value={formData.first_name} onChange={handleChange} />
									<span className="error"> {error['first_name']} </span>
								</label>
								<label class="col2 last">
								<input  type="text" placeholder="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} />
								<span className="error"> {error['last_name']} </span>
								</label>
							</div>
							<div class="clearfix">
								<label class="col2 first">
									<input type="Email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
									<span className="error"> {error['email']} </span>
								</label>
								<label class="col2 last">
									<input type="text" placeholder="Contact Number" name="contact" value={formData.contact} onChange={handleChange} />
									<span className="error"> {error['contact']} </span>
								</label>
							</div>
							<div class="clearfix">
								<textarea cols="30" rows="7" placeholder="Your Message Here..." name="message" value={formData.message} onChange={handleChange} ></textarea>
							</div>
							<div class="clearfix"><button type="submit" className="btn" value="Send" > { loading ? <>Loading...</> : <> Send <ion-icon name="send-sharp"></ion-icon> </> } </button></div>
						</form>
					</div>

				</div>
			</section>


            <Footer />
        </div>
    )
}

export default ContactUs;